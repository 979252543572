





import Vue from 'vue'
import { Component } from 'vue-property-decorator'
@Component
export default class WeChatCallback extends Vue {
  created(): void {
    let code = this.$route.query.code || ''
    let state = this.$route.query.state || ''
    console.log('code', code)
    console.log('state', state)
    if (code && state) {
      console.log('otherLogin')
      this.$router.push({
        name: 'otherLogin',
        query: {
          type: 'wx',
          code: code,
          state: state
        }
      })
    } else {
      console.log('login')
      this.$message.error('授权失败,请重新登录')
      this.$router.push({ name: 'login' })
    }
  }
}
